<script setup lang="ts">
const { getCommonLayoutData } = usePlayaLayout()

defineLayout({
  label: 'Kimpton Layout',
  colors: {
    fields: {
      picker: true,
      palette: true,
    },
    background: {
      picker: true,
      palette: true,
    },
    foreground: {
      picker: true,
      palette: true,
    },
  },

  colorPalette: {
    '#20432C': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#7A8F70': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#DF6548': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#FA9CA2': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#E7AD16': {
      fields: true,
      background: true,
      foreground: true,
    },
  },
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)

const {
  resorts,
  currentResort,
} = await getCommonLayoutData(isVoixTemplatizer, ['brands', 'footerCompanyMenu', 'footerResourcesMenu', 'footerTermsMenu'])

provide('accentColor', { value: '#DF6548' })
provide('currentResort', currentResort)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white kimpton-theme"
    >
      <div id="app" class="bg-bonita-inverse">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />
        <div id="kimpton-content">
          <KimptonNavigation
            v-if="!isVoixTemplatizer"
            :current-resort="currentResort?.data"
            class="font-sans"
            role="landmark"
          />

          <div class="text-gray-500">
            <slot />
          </div>

          <KimptonFooter v-if="!isVoixTemplatizer" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/kimpton.scss';
</style>
