<script setup lang="ts">
const props = defineProps<{
  currentResort: object
}>()

const mobileMenu = ref(false)

const showBookingModal = ref(false)
</script>

<template>
  <div class="bg-abs-black ">
    <div class="relative container mx-auto flex space-x-4 xl:justify-between">
      <div class="py-4 flex items-center space-x-8 ">
        <button class="xl:hidden" @click="mobileMenu = !mobileMenu">
          <Icon name="heroicons:bars-3-20-solid" class="text-white w-8 h-8" />
        </button>
        <LogosKimpton class="w-[150px] lg:w-[250px] invert" />
      </div>

      <div class="hidden xl:flex">
        <ul v-if="currentResort?.menu" class="flex pt-8 pb-4 pr-12 space-x-4">
          <li v-for="item in currentResort.menu.menu_items" :key="item.name" class="text-white uppercase font-light text-sm">
            <a :href="item.permalink" class="text-white">{{ item.name }}</a>
          </li>
        </ul>
        <div class="relative h-full">
          <button class="bg-kimpton-action h-full text-white px-8 uppercase font-bold" @click="showBookingModal = !showBookingModal">
            Book Now
          </button>
          <div class="down-triangle" />
        </div>
      </div>
    </div>
    <div
      v-if="showBookingModal"
      class="relative px-8 pt-4 z-50 flex justify-center inline-flight-booking w-full bg-abs-black"
    >
      <button class="absolute top-0 right-0 text-xs uppercase m-4 text-gray-200" @click="showBookingModal = !showBookingModal">
        Close
      </button>

      <!-- <FlightBookingForm /> -->
    </div>

    <div
      class="absolute z-50 inset-0 bg-white p-12 duration-500"
      :class="{
        '-translate-x-full': !mobileMenu,
        'translate-x-0': mobileMenu,
      }"
    >
      <div class="flex justify-between items-start">
        <img src="/imgs/kimpton/kimpton-logo.png" alt="Kimpton Logo" class="w-[150px]">
        <button @click="mobileMenu = !mobileMenu">
          <Icon name="heroicons:x-mark-20-solid" class="text-black w-12 h-12" />
        </button>
      </div>

      <button class="mt-4 bg-kimpton-action text-white px-8 py-4 uppercase font-bold">
        Book Now
      </button>

      <div class="mt-12 flex flex-col space-y-12">
        <ul class="flex flex-col space-y-8">
          <li v-for="item in currentResort?.menu.menu_items" :key="item.name" class="text-white uppercase font-bold text-lg">
            <a :href="item.permalink" class="text-black">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .down-triangle {
    border-left: 73px solid transparent;
    border-right: 73px solid transparent;
    border-top: 20px solid #df6548;

    @apply absolute z-100 -bottom-[20px] left-0 w-full;
  }
</style>
