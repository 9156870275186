<script setup lang="ts">

</script>

<template>
  <div id="footer" name="footer" class="bg-abs-black min-h-[250px] py-8 text-gray-400 font-light">
    <div class="container mx-auto space-y-12">
      <div class="grid lg:grid-cols-12 gap-16">
        <div class="lg:col-span-2 lg:col-start-2">
          <img src="/imgs/kimpton/kimpton-logo.png" alt="Kimpton Logo" class="w-[150px]">
        </div>

        <div class="lg:col-span-7 space-y-2">
          <div class="text-xl uppercase text-gray-100">
            1001 First Avenue Seattle, WA 98104
          </div>
          <div class="flex items-center space-x-6 text-xs">
            <div>
              Reservations: (555) 555-5555
            </div>
            <div>
              Hotel: (555) 555-5555
            </div>
            <div>
              Fax: (555) 555-5555
            </div>
          </div>
        </div>

        <div class="flex space-x-2">
          <a href="#" class="text-white">
            <Icon name="cib:twitter" class=" w-4 h-4" />
          </a>
          <a href="#" class="text-white">
            <Icon name="cib:facebook" class=" w-4 h-4" />
          </a>
          <a href="#" class="text-white">
            <Icon name="cib:linkedin" class=" w-4 h-4" />
          </a>
        </div>
      </div>

      <div class="py-12 grid lg:grid-cols-12 gap-16 border-y border-gray-800">
        <ul class="lg:col-span-3 space-y-1.5">
          <li>
            <a href="#" class="text-gray-400 text-sm">About Kimpton Hotels</a>
          </li>
          <li>
            <a href="#" class="text-gray-400 text-sm">Rewards Club</a>
          </li>
          <li>
            <a href="#" class="text-gray-400 text-sm">FAQs</a>
          </li>
        </ul>
        <ul class="lg:col-span-3 space-y-1.5">
          <li>
            <a href="#" class="text-gray-400 text-sm">Kimpton Blog: Life is Suite</a>
          </li>
          <li>
            <a href="#" class="text-gray-400 text-sm">Careers</a>
          </li>
          <li>
            <a href="#" class="text-gray-400 text-sm">Press Room</a>
          </li>
        </ul>
        <ul class="lg:col-span-3 space-y-1.5">
          <li>
            <a href="#" class="text-gray-400 text-sm">Contact Us</a>
          </li>
          <li>
            <a href="#" class="text-gray-400 text-sm">Privacy + Legal</a>
          </li>
          <li>
            <a href="#" class="text-gray-400 text-sm">Your CA Privacy Rights</a>
          </li>
          <li>
            <a href="#" class="text-gray-400 text-sm">Sitemap</a>
          </li>
        </ul>
        <div class="lg:col-span-3 text-2xl">
          1-555-5555
        </div>
      </div>

      <div class="text-sm text-gray-500">
        © 2024 Playa Resorts Management, LLC. All rights reserved.
      </div>
    </div>
  </div>
</template>
